import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";

import { Layout } from "components/layout";
import { SEO } from "components/seo/seo";
import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper";
import { ContentBlocks } from "components/content-blocks/ContentBlocks";
import { GlobalFooter } from "components/global-footer/GlobalFooter";
import { LoginHero } from "components/hero/LoginHero/LoginHero";

type PageContext = PageProps["pageContext"] & {
  slug: string
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  linkedPagesUrlPathCodex: Record<string, string>
}

interface LoginPageProps extends PageProps {
  pageContext: PageContext
}

const LoginPage: React.FC<LoginPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location;
  const data = useStaticQuery(GetLoginData);
  const [page] = data.allKontentItemPage.nodes;
  const {
    banner,
    global_footer,
    rich_content,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
  } = page.elements;
  const { slug, linkedPagesUrlPathCodex } = pageContext;

  return (
    <Layout
      hash={ hash }
      linkedPagesUrlPathCodex={ linkedPagesUrlPathCodex }
      location={ slug }
      pathName={ pathname }
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        noIndex />
      <LoginHero banner={banner.value[0].elements} />

      <BlocksWrapper>
        <ContentBlocks content={ rich_content.modular_content } />
      </BlocksWrapper>

      <GlobalFooter content={ global_footer } meta={ pageContext } />
    </Layout>
  )
}

const GetLoginData = graphql`
  query GetLoginData {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "login" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...LoginBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default LoginPage
