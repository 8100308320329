import React, { ChangeEvent, HTMLInputTypeAttribute } from "react"

interface FormFieldProps {
  errorMsg: string
  fieldName: string
  label: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  prefix?: string
  required?: boolean
  success?: boolean
  type?: HTMLInputTypeAttribute
  value: string
}

export const FormField = ({
  errorMsg,
  fieldName,
  label,
  onChange,
  prefix = "fr-login-form",
  required,
  success,
  type = "text",
  value,
}: FormFieldProps) => {
  const activeErrorMsgClass =
    errorMsg !== "" ? " fr-login-form__field-error--active" : ""
  const checkmarkSVGIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.5" viewBox="0 0 16 12.5"><path id="checkmark" d="M16.071,5.784l-7.5,7.5-3.5-3.5-2.5,2.5,6,6,10-10Z" transform="translate(-2.571 -5.784)" fill="#00bc1f"/></svg>'

  return (
    <div className={`${prefix}__field-wrapper`}>
      <div className={`${prefix}__label-wrapper`}>
        <label className={`${prefix}__label`} htmlFor={fieldName}>
          {label}
          {required && <abbr title="required">*</abbr>}
        </label>
        <input
          required={required}
          className={`${prefix}__field`}
          id={fieldName}
          name={fieldName}
          onChange={onChange}
          type={type}
          value={value}
        />
      </div>
      {success && (
        <div
          className={`${prefix}__field-success`}
          dangerouslySetInnerHTML={{ __html: checkmarkSVGIcon }}
        />
      )}
      <span className={`${prefix}__field-error${activeErrorMsgClass}`}>
        {errorMsg !== "" && errorMsg}
      </span>
    </div>
  )
}
