import React, { ChangeEvent, useState, useEffect } from "react"
import "./login.module.scss"

import { appendScript } from "assets/helper/appendScript"
import { ExternalLink } from "components/external-link/ExternalLink"
import { FormField } from "./FormField"

type AuthenticatedPortalLink = {
  clientMessage: string
  loginLink: string
  success: boolean
}

export const LoginForm = () => {
  const prefix: string = "fr-login-form"

  const [errors, setErrors] = useState({
    general: "",
    password: "",
    user: "",
  })
  const [isRender, setIsRender] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [subDomain, setSubDomain] = useState<string>("")
  const [user, setUser] = useState<string>("")
  const windowAvailable = typeof window !== "undefined"
  const loginMessage = "Your Username / Password is incorrect"
  /* eslint-disable */
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    // @ts-ignore: PestportalsClient was delayed imported
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then((response: AuthenticatedPortalLink) => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        if (user === "")
          setErrors(oldErrors => ({
            ...oldErrors,
            user: "This field is required",
          }))
        else setErrors(oldErrors => ({ ...oldErrors, user: "" }))

        if (password === "")
          setErrors(oldErrors => ({
            ...oldErrors,
            password: "This field is required",
          }))
        else setErrors(oldErrors => ({ ...oldErrors, password: "" }))

        if (password !== "")
          windowAvailable && window.location.replace("/login?error=invalid")
      }
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.name === "acc_num"
      ? setUser(e.target.value)
      : setPassword(e.target.value)
  }

  const script = () => {
    !(window as any).jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })
    // @ts-ignore: PestportalsClient was delayed imported
    if ((window as any).jQuery && typeof PestportalsClient !== "object")
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    else setTimeout(script, 200)
  }

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setSubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  return (
    <form className={prefix}>
      <div className={`${prefix}__wrapper`}>
        <div className={`${prefix}__heading-wrapper`}>
          <h2>Login</h2>
        </div>

        <FormField
          required
          errorMsg={errors.user}
          fieldName="acc_num"
          label="Account Number:"
          onChange={handleChange}
          value={user}
        />
        <FormField
          required
          errorMsg={errors.password}
          fieldName="pass"
          label="Password:"
          onChange={handleChange}
          type="password"
          value={password}
        />

        <div className={`${prefix}__btn-wrapper`}>
          <button
            className={`button--solid-secondary ${prefix}__submit-btn`}
            onClick={handleSubmit}
            role="button"
            type="submit"
          >
            {" "}
            Login{" "}
          </button>
        </div>

        <p className={`${prefix}__recover-pw`}>
          Don't know your password?{" "}
          <ExternalLink
            href={`https://${subDomain}.pestportals.com/forgotPassword`}
          >
            Get Login Info
          </ExternalLink>
        </p>

        {errors.general && (
          <div className={`${prefix}__error-wrapper`}>
            <span>{errors.general}</span>
          </div>
        )}
        {!errors.general &&
          windowAvailable &&
          window.location.href.includes("?error=invalid") && (
            <div className={`${prefix}__error-wrapper`}>
              <span>{loginMessage}</span>
            </div>
          )}
      </div>
    </form>
  )
}
